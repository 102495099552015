import React from 'react';
import './styles/style.css';
import './styles/normalize.css';
import Auth from './auth/Auth';
import BasicTools from './tools/components/basictools/BasicTools';
import { useSelector } from 'react-redux';
import { account_user } from './store/account/selector.account';

function App() {
  const account = useSelector(account_user)

  return (
    <React.Fragment>
      <BasicTools />
      <Auth />
    </React.Fragment>
  );
}

export default App;
