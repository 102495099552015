import React, { useState } from 'react' ; 
import {motion} from 'framer-motion' ; 
import { ServerProps } from "../../config/server.config";
import {ReactComponent as Logo} from '../../assets/svg/logo.svg' ; 
import {ReactComponent as ForgotPwdIll} from '../../assets/svg/illustrations/forgot-pwd.svg' ; 
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addRequestStatus } from '../../store/global/actions.global';
import ContainerLoad from '../../tools/components/general/ContainerLoad';
import Code from './layouts/Code';
import Pwd from './layouts/Pwd';
import Form from './layouts/Form';
import { Icons } from 'tc-minibox';
import { icons_config } from '../../config/context.config';

interface ForgotPwdManager {
    mode : "form" | "code" | "load" | "pwd"
}

type ForgotPwdForm = {
    mail : string,
    token? : string,
    code? : string,
}

export const initialForm = {mail : "", code : ""}

export interface ChildrenSignUpProps extends ServerProps {
    handleReturn : () => void,
    form : ForgotPwdForm,
    setForm : React.Dispatch<React.SetStateAction<ForgotPwdForm>>,
    setManager : React.Dispatch<React.SetStateAction<ForgotPwdManager>>, 
    handleSubmit : () => void,
}


export default function ForgotPwd(props:ServerProps) {
    // * GENERAL VARIABLES
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // * STATES
    const [manager, setManager] = useState<ForgotPwdManager>({mode :  "form"})
    const [form, setForm] = useState<ForgotPwdForm>(initialForm)

    // * FUNCTIONS
    const handleReturn = () => {
        const status = manager.mode
        const val = status === "code" ? "form" : "form"
        if(manager.mode === "form") { navigate('/')}
        else {setManager(state => {return{...state, mode : val}})}
    }

    const handleSubmit = () => {
        if(!form.mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)) {
            let response = {
                status : false, 
                message : "Le format de l'adresse mail est invalide."
            }
            return dispatch(addRequestStatus(response))
        }

        setManager(state => {return{...state, mode : "load"}})

        return props.server.post('forgot-pwd', {mail : form.mail})
        .then(res => {
            setManager(state => {return{...state, mode : "code"}})
            setForm(state => {return{...state, token : res.data.token}})
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            setManager(state => {return{...state, mode : "form"}})
            dispatch(addRequestStatus(err.response.data))
        })


    }

    const children_props:ChildrenSignUpProps = {
        server : props.server, 
        handleReturn : handleReturn,
        form : form,
        setForm : setForm, 
        setManager : setManager, 
        handleSubmit : handleSubmit
    }

    return (
        <div className = "forgot-pwd">
            <motion.div 
                {...{
                    initial : {opacity : 0, y : 100},
                    animate : {opacity : 1, y : 0},
                    exit : {opacity : 0, y : -100},
                }}
                className='forgot-pwd--container'
            >
                {
                    manager.mode !== "load" ? 
                        <motion.div className='return' onClick = {handleReturn}>
                            <Icons name = "arrow-turn" {...icons_config} />
                        </motion.div>
                    :  
                        null
                }
                <ContainerLoad load = {manager.mode === "load"}>
                    {
                        manager.mode === "code" ?
                            <Code {...children_props}/>
                        : manager.mode === "pwd" ? 
                            <Pwd {...children_props} />
                        : 
                            <Form {...children_props} />
                    }
                </ContainerLoad>
            </motion.div>

            <motion.div className='logo'><Logo /></motion.div>
            <motion.div className='illustration'><ForgotPwdIll /></motion.div>
            
        </div>
    )
}


// FORM 
// --> Enter mail --> fetch (GENERAL)

// CODE
// --> Enter code --> fetch (INDIVIDUAL)

//Password 
// --> Enter password --> fetch (INDIVIDUAL)

